import { useEffect, useState } from 'react';

const ProgressBar = ({ precheckConfig, currentPageIndex, precheckData }) => {
  const [stepNumber, setStepNumber] = useState(1);
  const [visitedSteps, setVisitedSteps] = useState({});
  const stepName = precheckConfig[currentPageIndex];

  let numberOfSteps = 0;

  useEffect(() => {
    if (visitedSteps[stepName]) {
      return;
    }

    const advanceStepConditions = [
      stepName === 'LMI_DOWNLOAD',
      stepName === 'PAYMENTS',
      stepName === 'SHARESCREENPAGE',
      stepName === 'ALLOWED_RESOURCES',
      stepName === 'OTHER_RESOURCES' &&
        !precheckConfig.includes('ALLOWED_RESOURCES'),
      stepName === 'GUIDELINES',
      stepName === 'PROFILE_PHOTO',
      stepName === 'ID_STEP' && !precheckConfig.includes('PROFILE_PHOTO'),
      stepName === 'GUIDED_LAUNCH_INSTRUCTIONS',
      stepName === 'EXAM_LOBBY',
    ];

    if (advanceStepConditions.some(Boolean)) {
      setStepNumber(stepNumber + 1);
    }

    setVisitedSteps({ ...visitedSteps, [stepName]: true });
  }, [currentPageIndex]);

  const progressBars = () => {
    const welcomeBar = () => {
      const hasWelcomePage = precheckConfig.some((step) =>
        step.includes('WELCOME'),
      );

      if (hasWelcomePage) {
        numberOfSteps++;
      }

      return (
        <div
          className={`precheck-bg-light d-none flex-fill ${
            hasWelcomePage ? 'd-block' : ''
          }`}
          id="welcome-div"
        >
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={currentPageIndex === 0 ? 'step' : undefined}
          >
            {polyglot.t('prechecks_progress_bar_welcome')}
          </p>
          <div
            className="btn-precheck-primary"
            style={{ height: 4, minWidth: 89 }}
          ></div>
        </div>
      );
    };

    const installLMIBar = () => {
      const hasLMIDownload = precheckConfig.includes('LMI_DOWNLOAD');
      const hasVisitedLMIDownload =
        stepName?.startsWith('LMI_DOWNLOAD') || visitedSteps['LMI_DOWNLOAD'];

      if (hasLMIDownload) {
        numberOfSteps++;
      }

      return (
        <div
          className={`precheck-bg-light d-none flex-fill ${
            hasLMIDownload ? 'd-block' : ''
          }`}
          id="install-lmi-div"
        >
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={stepName === 'LMI_DOWNLOAD' ? 'step' : undefined}
          >
            {progressBarFirstTitle()}
          </p>
          <div
            className={`precheck-progress-bar ${
              hasVisitedLMIDownload ? 'btn-precheck-primary' : ''
            }`}
            id="install-lmi-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const progressBarFirstTitle = () => {
      if (precheckData.integratedLmiChat)
        return polyglot.t('prechecks_progress_bar_get_started');
      return polyglot.t('prechecks_progress_bar_install_lmi');
    };

    const paymentBar = () => {
      if (precheckConfig.includes('PAYMENTS')) {
        const paymentProgressDiv = document.getElementById('payment-div');
        paymentProgressDiv.classList.add('d-block');
        numberOfSteps += 1;
      }
      if (precheckConfig[currentPageIndex] === 'PAYMENTS') {
        const paymentProgressBar = document.getElementById('payment-bar');
        paymentProgressBar.classList.add('btn-precheck-primary');
      }

      return (
        <div className="precheck-bg-light d-none flex-fill" id="payment-div">
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={stepName === 'PAYMENTS' ? 'step' : undefined}
          >
            {polyglot.t('prechecks_progress_bar_payment')}
          </p>
          <div
            className="precheck-progress-bar"
            id="payment-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const beginRecordingBar = () => {
      if (precheckConfig.includes('SHARESCREENPAGE')) {
        const beginRecordingProgressDiv = document.getElementById(
          'begin-recording-div',
        );
        beginRecordingProgressDiv.classList.add('d-block');
        numberOfSteps += 1;
      }
      if (precheckConfig[currentPageIndex] === 'SHARESCREENPAGE') {
        const beginRecordingProgressBar = document.getElementById(
          'begin-recording-bar',
        );
        beginRecordingProgressBar.classList.add('btn-precheck-primary');
      }

      return (
        <div
          className="precheck-bg-light d-none flex-fill"
          id="begin-recording-div"
        >
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={stepName === 'SHARESCREENPAGE' ? 'step' : undefined}
          >
            {polyglot.t('prechecks_progress_bar_begin_recording')}
          </p>
          <div
            className="precheck-progress-bar"
            id="begin-recording-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const examRulesBar = () => {
      if (
        precheckConfig.includes('ALLOWED_RESOURCES') ||
        precheckConfig.includes('OTHER_RESOURCES') ||
        precheckConfig.includes('GUIDED_LAUNCH_SELECT_RESOURCES') ||
        precheckConfig.includes('GUIDED_LAUNCH_RESOURCE_CHECK')
      ) {
        const examRulesProgressDiv = document.getElementById('exam-rules-div');
        examRulesProgressDiv.classList.add('d-block');
        numberOfSteps += 1;
      }

      const isCurrentStepExamRules =
        stepName === 'ALLOWED_RESOURCES' ||
        stepName === 'OTHER_RESOURCES' ||
        stepName === 'GUIDED_LAUNCH_SELECT_RESOURCES' ||
        stepName === 'GUIDED_LAUNCH_RESOURCE_CHECK';

      if (
        precheckConfig[currentPageIndex] === 'ALLOWED_RESOURCES' ||
        precheckConfig[currentPageIndex] === 'OTHER_RESOURCES' ||
        precheckConfig[currentPageIndex] === 'GUIDED_LAUNCH_SELECT_RESOURCES' ||
        precheckConfig[currentPageIndex] === 'GUIDED_LAUNCH_RESOURCE_CHECK'
      ) {
        const examRulesProgressBar = document.getElementById('exam-rules-bar');
        examRulesProgressBar.classList.add('btn-precheck-primary');
      }

      return (
        <div className="precheck-bg-light d-none flex-fill" id="exam-rules-div">
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={isCurrentStepExamRules ? 'step' : undefined}
          >
            {polyglot.t('prechecks_progress_bar_exam_rules')}
          </p>
          <div
            className="precheck-progress-bar"
            id="exam-rules-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const accommodationsBar = () => {
      if (precheckConfig.includes('GUIDELINES')) {
        const accommodationsProgressDiv =
          document.getElementById('accommodations-div');
        accommodationsProgressDiv.classList.add('d-block');
        numberOfSteps += 1;
      }
      if (precheckConfig[currentPageIndex] === 'GUIDELINES') {
        const accommodationsProgressBar =
          document.getElementById('accommodations-bar');
        accommodationsProgressBar.classList.add('btn-precheck-primary');
      }

      return (
        <div
          className="precheck-bg-light d-none flex-fill"
          id="accommodations-div"
        >
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={stepName === 'GUIDELINES' ? 'step' : undefined}
          >
            {polyglot.t('prechecks_progress_bar_accommodations')}
          </p>
          <div
            className="precheck-progress-bar"
            id="accommodations-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const verifyIDBar = () => {
      if (
        precheckConfig.includes('PROFILE_PHOTO') ||
        precheckConfig.includes('ID_STEP')
      ) {
        const verifyIDProgressDiv = document.getElementById('verify-id-div');
        verifyIDProgressDiv.classList.add('d-block');
        numberOfSteps += 1;
      }
      if (
        precheckConfig[currentPageIndex] === 'PROFILE_PHOTO' ||
        precheckConfig[currentPageIndex] === 'ID_STEP'
      ) {
        const verifyIDProgressBar = document.getElementById('verify-id-bar');
        verifyIDProgressBar.classList.add('btn-precheck-primary');
      }

      return (
        <div className="precheck-bg-light d-none flex-fill" id="verify-id-div">
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={
              stepName === 'PROFILE_PHOTO' || stepName === 'ID_STEP'
                ? 'step'
                : undefined
            }
          >
            {polyglot.t('prechecks_progress_bar_verify_id')}
          </p>
          <div
            className="precheck-progress-bar"
            id="verify-id-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const roomScanBar = () => {
      if (precheckConfig.includes('GUIDED_LAUNCH_INSTRUCTIONS')) {
        const roomScanProgressDiv = document.getElementById('room-scan-div');
        roomScanProgressDiv.classList.add('d-block');
        numberOfSteps += 1;
      }
      if (precheckConfig[currentPageIndex] === 'GUIDED_LAUNCH_INSTRUCTIONS') {
        const roomScanProgressBar = document.getElementById('room-scan-bar');
        roomScanProgressBar.classList.add('btn-precheck-primary');
      }

      return (
        <div className="precheck-bg-light d-none flex-fill" id="room-scan-div">
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={
              stepName === 'GUIDED_LAUNCH_INSTRUCTIONS' ? 'step' : undefined
            }
          >
            {polyglot.t('prechecks_progress_bar_room_scan')}
          </p>
          <div
            className="precheck-progress-bar"
            id="room-scan-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const secondCameraBar = () => {
      if (precheckConfig.includes('SECOND_CAMERA_LAUNCH')) {
        const roomScanProgressDiv =
          document.getElementById('second-camera-div');
        roomScanProgressDiv.classList.add('d-block');
        numberOfSteps += 1;
      }
      if (precheckConfig[currentPageIndex] === 'SECOND_CAMERA_LAUNCH') {
        const roomScanProgressBar =
          document.getElementById('second-camera-bar');
        roomScanProgressBar.classList.add('btn-precheck-primary');
      }

      const isCurrentStepSecondCamera =
        stepName === 'SECOND_CAMERA_LAUNCH' ||
        stepName === 'SECOND_CAMERA_POSITIONING';

      return (
        <div
          className="precheck-bg-light d-none flex-fill"
          id="second-camera-div"
        >
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={isCurrentStepSecondCamera ? 'step' : undefined}
          >
            {polyglot.t('prechecks_progress_bar_second_camera')}
          </p>
          <div
            className="precheck-progress-bar"
            id="second-camera-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    const examLobbyBar = () => {
      const hasExamLobby = precheckConfig.includes('EXAM_LOBBY');
      const isOnExamLobby = stepName === 'EXAM_LOBBY';

      if (hasExamLobby) {
        numberOfSteps += 1;
      }

      return (
        <div
          className={`precheck-bg-light d-none flex-fill ${
            hasExamLobby ? 'd-block' : ''
          }`}
          id="exam-lobby-div"
        >
          <p
            className="precheck-progress-bar-text text-left precheck-gray"
            tabIndex={0}
            aria-current={isOnExamLobby ? 'step' : undefined}
          >
            {polyglot.t('prechecks_progress_bar_exam_lobby')}
          </p>
          <div
            className={`precheck-progress-bar ${
              isOnExamLobby ? 'btn-precheck-primary' : ''
            }`}
            id="exam-lobby-bar"
            style={{ minWidth: 87 }}
          ></div>
        </div>
      );
    };

    return (
      <div
        className="d-flex justify-content-between flex-wrap"
        aria-label="Step Indicator"
      >
        {welcomeBar()}
        {installLMIBar()}
        {paymentBar()}
        {beginRecordingBar()}
        {examRulesBar()}
        {accommodationsBar()}
        {verifyIDBar()}
        {roomScanBar()}
        {secondCameraBar()}
        {examLobbyBar()}
      </div>
    );
  };

  return (
    <div className="precheck-progress-bar-container">
      {progressBars()}
      <div className="text-right precheck-primary">
        <p className="precheck-progress-bar-text-completed">
          {stepNumber}/{numberOfSteps}{' '}
          {polyglot.t('prechecks_progress_bar_completed')}
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;

import { useEffect, useState, useRef } from 'react';
import ImageGroup from '../Shared/ImageGroup';
import PrechecksBodyCamera from './CardSections/PrechecksBodyCamera';
import PrechecksBodyText from './CardSections/PrechecksBodyText';
import PrechecksHeaderGroup from './CardSections/PrechecksHeaderGroup';
import PrechecksFooterButton from './CardSections/PrechecksFooterButton';
import { PrechecksVideoPlayer } from './PrechecksVideoPlayer';

const GuidedLaunchCaptureComputer = ({
  id,
  icon,
  iconPrefix,
  title,
  paragraph1,
  paragraph2,
  paragraph3,
  your_image_text,
  btnText,
  backBtnText,
  handleNext,
  handlePrevious,
  sendEvent,
  streamWebcam,
  precheckData,
  glImages,
  getGlImages,
}) => {
  const userId = precheckData.user.id;
  const [pictureTaken, setPictureTaken] = useState(false);
  const canvasRef = useRef();
  const videoRef = useRef();
  const height = 240;
  const width = 320;
  const deskDevicesImg = precheckData.images.deskDevicesImg;

  useEffect(() => {
    sendEvent('Event::CaptureComputerLoaded');
    streamWebcam(videoRef.current);
  }, []);

  useEffect(() => {
    getGlImages();
  }, []);

  const handleBack = () => {
    sendEvent('Event::BackOnCaptureComputer');
    handlePrevious();
  };

  const handleGuidedLaunchCaptureComputerBtn = () => {
    const context = canvasRef.current.getContext('2d');
    context.translate(0, 0);
    context.drawImage(videoRef.current, 0, 0, width, height);

    sendEvent(
      'Event::CaptureComputerCompleted',
      {
        createdById: userId,
        attachmentUrl: canvasRef.current.toDataURL('image/png'),
      },
      'image_checks',
      (response) => {
        if (response.status === 201) {
          setPictureTaken(true);

          handleNext();
        } else {
          setPictureTaken(false);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return (
    <div className="container-fluid text-center precheck-card-content" id={id}>
      <PrechecksHeaderGroup
        title={title}
        image={deskDevicesImg}
        icon={icon}
        iconPrefix={iconPrefix}
      />
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph1} />
      </div>
      <div className="text-left">
        <PrechecksBodyText bodyText={paragraph2} />
      </div>
      <div className={'row align-items-center'}>
        <div className={'col-md-6'}>
          <div className={'row my-3 precheck-card-body'}>
            <div className={'col-md-12'}>
              <p>Example</p>
            </div>
          </div>
          <div className={'row my-3'}>
            <div className={'col-md-12'}>
              <PrechecksVideoPlayer
                videoInfo={precheckData.videos.computerKeyboardMp4}
                idPrefix="computer-keyboard"
              />
            </div>
          </div>
        </div>
        <div className={'col-md-6'}>
          <PrechecksBodyText bodyText={your_image_text} />
          <PrechecksBodyCamera
            canvasRef={canvasRef}
            videoRef={videoRef}
            showCanvas={pictureTaken}
            showVideo={!pictureTaken}
            height={height}
            width={width}
            precheckData={precheckData}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <PrechecksFooterButton
          buttonText={backBtnText}
          handleClick={handleBack}
          buttonStyleClass="btn-precheck-secondary"
        />
        <PrechecksFooterButton
          buttonText={btnText}
          handleClick={handleGuidedLaunchCaptureComputerBtn}
        />
      </div>
    </div>
  );
};

GuidedLaunchCaptureComputer.defaultProps = {
  title: polyglot.t('prechecks_guidedlaunch_capture_computer_title'),
  paragraph1: polyglot.t('prechecks_guidedlaunch_capture_computer_paragraph1'),
  paragraph2: polyglot.t('prechecks_guidedlaunch_capture_computer_paragraph2'),
  your_image_text: polyglot.t(
    'prechecks_guidedlaunch_capture_computer_your_image_text',
  ),
  btnText: polyglot.t('prechecks_guidedlaunch_capture_computer_button'),
  backBtnText: polyglot.t(
    'prechecks_guidedlaunch_capture_computer_back_button',
  ),
  icon: 'fa-camera',
  iconPrefix: 'fal',
  statusColor: 'primary',
  iconColor: 'primary',
};

export default GuidedLaunchCaptureComputer;

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useContext, useState } from 'react';
import ChargeDevice from '../../../assets/images/second_camera/charge-device.svg';
import PhoneScanning from '../../../assets/images/second_camera/phone-scanning.svg';
import { StreamContext } from './contexts';
import { getPrioritizedDeviceList } from './DeviceHelper';
import { useTranslate } from 'react-polyglot';

const MediaPermission = (props) => {
  const t = useTranslate();
  const [permissionDenied, setPermissionDenied] = useState(false);
  const { setDeviceList, setSelectedDevice, setVideoStream } =
    useContext(StreamContext);

  let requestCamPermission = () => {
    // get users permission to use camera
    const aspectRatio = (16 / 9).toFixed(2);
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then(async (stream) => {
        // for some Android devices before calling navigator.mediaDevices.getUserMedia all active tracks must be stopped
        stream.getVideoTracks().forEach((t) => t.stop());
        // wait for old track is stopped and call navigator.mediaDevices.getUserMedia after that
        await new Promise((r) => setTimeout(r, 1000));
        // after getting perm, get details of all devices
        // we need this to filter out front cameras
        navigator.mediaDevices
          .enumerateDevices({
            video: {
              aspectRatio: {
                ideal: aspectRatio,
              },
            },
            audio: false,
          })
          .then((devices) => {
            let videoDevices = devices.filter((d) => d.kind === 'videoinput');
            setDeviceList(videoDevices);
            let prioritizedDevices = getPrioritizedDeviceList(videoDevices);
            // get the default camera that will be shown first on user's screen
            navigator.mediaDevices
              .getUserMedia({
                video: {
                  deviceId: prioritizedDevices[0].device.deviceId,
                  aspectRatio: {
                    ideal: aspectRatio,
                  },
                },
                audio: false,
              })
              .then((stream) => {
                let deviceId = stream
                  .getVideoTracks()[0]
                  .getSettings().deviceId;
                setSelectedDevice(deviceId);
                setVideoStream(stream);
              });
          });
        props.setCurrentStep('POSITION_PHONE');
      })
      .catch((err) => {
        if (err instanceof DOMException && err.name === 'NotAllowedError') {
          setPermissionDenied(true);
        } else {
          console.log('error capturing media', err);
        }
      });
  };
  return (
    <>
      <Row className="mt-5">
        <Col className="col-6 offset-3">
          {permissionDenied && <Image className="w-100" src={PhoneScanning} />}
          {!permissionDenied && <Image className="w-100" src={ChargeDevice} />}
        </Col>
        <div className="w-100"></div>
        <Col className="col-12">
          {!permissionDenied && (
            <>
              <h6 className="mt-4 mb-4 text-center">
                {t('media_permission.plugin_heading')}
              </h6>
              <p className="ml-3 mr-3">{t('media_permission.plugin_outlet')}</p>
              <p className="ml-3 mr-3">
                {t('media_permission.begin_recording')}
              </p>
            </>
          )}
          {permissionDenied && (
            <>
              <h6 className="mt-4 mb-4 text-center">
                {t('media_permission.cam_permission_heading')}
              </h6>
              <p className="ml-3 mr-3">
                {t('media_permission.grant_camera_permission')}
              </p>
              <p className="ml-3 mr-3">
                {t('media_permission.begin_recording')}
              </p>
            </>
          )}
        </Col>
      </Row>
      <div className="text-center sc-nav-container">
        {!permissionDenied && (
          <Button variant="primary" onClick={requestCamPermission}>
            {t('media_permission.button.begin_recording')}
          </Button>
        )}
        {permissionDenied && (
          <Button variant="primary" onClick={requestCamPermission}>
            {t('media_permission.button.understand')}
          </Button>
        )}
      </div>
    </>
  );
};

export default MediaPermission;
